import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useI18n } from "src/utils/lni18n";
import { useAtom } from "jotai";
import courseService, { currentCourseAtom, oneCourseatom } from "src/course/CourseService";
import { isDialogOpen, useDialog } from "src/components/Modalservice/Dialogservice";
import HtmlTextEditor from "src/components/TextEditor/HtmlTextEditor";
import { debouncer } from "src/utils/debouncer";
import classes from "./diploma.module.scss";
import imagePickerService from "src/components/ImagePicker/ImagePickerService";
import ImagePickerDialog from "src/components/ImagePicker/ImagePickerDialog";
import { DiplomaSettingsDialog } from "./DiplomaSettingsDialog";
import { PORTAL_API_URL } from "src/utils/constants";
import { getEditorSettings } from "./getEditorSettings";


let mmToPxConversionFactor: number | null = null;



function mm2px(mm: number) {

    if (mmToPxConversionFactor === null) {

        const div = document.createElement("div");
        div.id = "mm2px";
        div.style.width = "100mm";
        div.style.height = "1mm";
        div.style.visibility = "hidden";
        document.body.appendChild(div);

        const pixels = div.offsetWidth;
        mmToPxConversionFactor = mm * pixels / 100;
        div.remove();
    }

    return mmToPxConversionFactor;
}


function imageSize(url: string) {

    return new Promise<{ width: number, height: number }>((resolve, reject) => {

        const div = document.createElement("div");
        div.id = "imgfgf";
        div.style.position = "absolute";
        div.style.top = "-10000px";
        document.body.appendChild(div);

        const img = document.createElement("img");
        img.src = url;
        img.onload = () => {
            console.log(img.offsetWidth, img.offsetHeight);
            div.remove();
            resolve({ width: img.offsetWidth, height: img.offsetHeight });

        };

        div.appendChild(img);


    });

}

export type DiplomaText = {
    Footer: string,
    Body: string,
    BgImage: BgImage,
    Padding: DiplomaPadding
}

export type DiplomaPadding = {
    Top: number,
    Bottom: number,
    Sides: number
}

export type BgImage = {
    Src: string,
    Top: number,
    Left: number,
    Size: number,
    ImageId: string
}



const Diploma = () => {

    const { languageService: t } = useI18n();

    const editorSettingsMain = getEditorSettings("mceCustomToolBar", t);
    const editorSettingsFooter = getEditorSettings("mceCustomToolBar", t);


    const saveDebounce = useRef<debouncer | undefined>(undefined);
    const previewForm = useRef<HTMLFormElement | null>(null);

    const [courseId] = useAtom(currentCourseAtom);
    const courseAtom = useMemo(() => oneCourseatom(courseId || ""), [courseId]);
    const [course] = useAtom(courseAtom);
    const dialogPortal = useDialog();


    const [previewUrl, setPreviewUrl] = useState(() => {

        if (import.meta.env.PROD) {
            return `${PORTAL_API_URL}/Diploma/Preview`;
        }
        else {
            return `${PORTAL_API_URL}/Diploma/Preview?ashtml=1`;
        }
    })

    const getPreviewData = (data: DiplomaText) => {

        const paddings = `padding: ${data.Padding.Top}mm ${data.Padding.Sides}mm ${data.Padding.Bottom}mm ${data.Padding.Sides}mm;`
        let bgImg: any = "";
        if (data.BgImage && data.BgImage.Src) {
            bgImg = `background-image: url(${data.BgImage.Src}); background-repeat: no-repeat; background-size: ${data.BgImage.Size}%; background-position-y: ${data.BgImage.Top}mm; background-position-x: ${data.BgImage.Left}mm;`;
        }

        return { paddings, bgImg };

    }

    const [diplomaData, setDiplomaData] = useState<DiplomaText>(() => {
        return course.DiplomaTemplate ? structuredClone(course.DiplomaTemplate) : {
            Padding: { Top: 10, Bottom: 20, Sides: 10 },
            BgImage: { ImageId: "", Left: 0, Top: 0, Src: "", Size: 100 },
            Body: "",
            Footer: ""
        }
    });

    const [previewData, setPreviewData] = useState(() => {

        const pData = getPreviewData(diplomaData);
        return {
            BgImg: pData.bgImg, Paddings: pData.paddings,
            Body: diplomaData.Body, Footer: diplomaData.Footer
        };

    });

    const [initialData] = useState(() => {
        if (course.DiplomaTemplate) {
            return { main: course.DiplomaTemplate.Body, footer: course.DiplomaTemplate.Footer };
        }
        return { main: "", footer: "" };
    });
    const [zoom, setZoom] = useState(100);

    const getStyles = (props: DiplomaText, zoomLevel: number) => {
        if (props.BgImage && props.BgImage.Src) {
            return {
                padding: `${props.Padding.Top}mm ${props.Padding.Sides}mm ${props.Padding.Bottom}mm`,
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${props.BgImage?.Src})`,
                backgroundSize: `${props.BgImage?.Size}%`,
                backgroundPosition: `${props.BgImage?.Left}mm ${props.BgImage?.Top}mm`,
                zoom: `${zoomLevel}%`
            }
        }
        else {
            return {
                padding: `${props.Padding.Top}mm ${props.Padding.Sides}mm ${props.Padding.Bottom}mm`,
                zoom: `${zoomLevel}%`
            };
        }
    }



    const [editorStyle, setEditorStyle] = useState(() => {
        return getStyles(diplomaData, zoom);
    });

    useEffect(() => {
        setEditorStyle(getStyles(diplomaData, zoom));
    }, [diplomaData, zoom]);



    useEffect(() => {
        saveDebounce.current = new debouncer(saveTemplate, 500, courseService);
        return () => {
            saveDebounce.current?.clear();
        }
    }, [])

    const saveText = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.currentTarget;
        const  name  = e.currentTarget.name as "Body" | "Footer";

        const data: DiplomaText = { ...diplomaData};
        data[name] = value;
        setDiplomaData(data);

    }, [diplomaData]);

   


    function saveTemplate(data: DiplomaText): void {
        courseService.saveDiploma(data, courseId!);
    }

    const previewDiploma = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!previewForm.current) return;
        if (import.meta.env.PROD && e.ctrlKey) {
            setPreviewUrl(`${PORTAL_API_URL}/Diploma/Preview?ashtml=1`);

            setTimeout(() => {
                setPreviewUrl(`${PORTAL_API_URL}/Diploma/Preview`);
            }, 1000);
        }

        previewForm.current.submit();
    }, [previewForm]);

    async function pickBgImage() {

        imagePickerService.fetchImageData();

        var res = await dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <ImagePickerDialog onCancel={onCancel} onSubmit={onSubmit} />
            },
            size: "xl"
        });

        imagePickerService.setModalState(false);
        imagePickerService.setAlingData(undefined);

        if (res) {
            let className = "";
            if (res.imageClasses !== "") {

                className = `class="${res.imageClasses}"`;
            }

            try {
                //const size = await imageSize(res.url);

                const imgData: BgImage = {
                    Src: res.url,
                    Top: 0,
                    Left: 0,
                    Size: 100,
                    ImageId: res.imageId!
                }

                let pixelWidth = mm2px(210);

                if (pixelWidth === 0) pixelWidth = 1024;

                // imgData.Size = Math.min(100, 100 * size.width / pixelWidth);

                setDiplomaData(d => ({ ...d, BgImage: imgData }));

            } catch (error) {
                console.error(error);

            }
        }
    };

 

    useEffect(() => {
        const data: DiplomaText = { ...diplomaData };
        saveDebounce.current && saveDebounce.current.debounce(data);

        const pData = getPreviewData(diplomaData);
        setPreviewData({ BgImg: pData.bgImg, Paddings: pData.paddings, Body: diplomaData.Body, Footer: diplomaData.Footer });

    }, [diplomaData]);


    const openPropDialog = useCallback(() => {

        if (isDialogOpen() === true) return;

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <DiplomaSettingsDialog props={diplomaData} setter={setDiplomaData} languageService={t} onSubmit={onSubmit} />
            },
            size: "sm"
        }).then((e) => { }).catch((e) => { });


    }, [setDiplomaData, diplomaData]);


    return (<>

        <div className="mb-3 diplomaToolBar" id="diplomaEditorToolbarMain"></div>

        <form ref={previewForm} action={previewUrl} target="_blank" method="post">
            <input type="hidden" name="html_body" value={previewData.Body} />
            <input type="hidden" name="html_bgimage" value={previewData.BgImg} />
            <input type="hidden" name="html_footer" value={previewData.Footer} />
            <input type="hidden" name="html_padding" value={previewData.Paddings} />
        </form>



        <div className="d-print-none">
            <h3 className="mb-3">{t.getText("diploma")}</h3>
            <div className="preserve-white border rounded p-3 mb-4">{t.getText("course.diploma.explained")}</div>

            <div className="d-flex mb-4 flex-wrap">

                <div className="btn-group btn-group-sm mr-3">
                    <button className="btn btn-small btn-primary" onClick={previewDiploma} >{t.getText("preview")}</button>
                    <button type="button" className="btn btn-small btn-primary" onClick={pickBgImage}>{t.getText("background")}</button>
                    <button type="button" className="btn btn-small btn-primary" onClick={openPropDialog}>{t.getText("properties")}</button>
                </div>

                <div className="">
                    <div className="d-flex align-items-center">
                        <div className="mr-3">Zoom</div>
                        <input value={zoom} onChange={(e) => setZoom(parseFloat(e.currentTarget.value))} type="range" min="30" max="100" step={1.0} />
                        <div className="ml-2">{zoom}%</div>
                    </div>
                </div>
            </div>


            <div id="mceCustomToolBar" className="mceCustomToolBar"></div>
            <div className="bg-grey p-5 position-relative overflow-y-auto">



                <div className={`m-auto ${classes.templateDoc}`} style={editorStyle}>


                    <div className={classes.editors}>
                        <div>

                            <HtmlTextEditor editorSettings={editorSettingsMain} readOnly={false} onChange={saveText} initialValue={initialData.main} name="Body"  ></HtmlTextEditor>
                        </div>
                        <div>
                            <div className="" id="diplomaEditorToolbarFooter"></div>
                            <HtmlTextEditor editorSettings={editorSettingsFooter} readOnly={false} onChange={saveText} initialValue={initialData.footer} name="Footer"  ></HtmlTextEditor>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </>
    )
}

export default Diploma;
