import { DiffTestDataDTO } from "../../DomainModels";
import DrawingTest from "../../DrawingTest/DrawingTest";
import TestDiffByReadOnly from "../TestDiffByReadOnly";

const DrawingTestDiff = (props: { data: DiffTestDataDTO }) => {

    const { data } = props;
    if (!data) return null;

    return <TestDiffByReadOnly data={data}
        publComponent={<DrawingTest readOnly={true} data={data.publDTO} onChange={() => { }} />}
        editComponent={<DrawingTest readOnly={true} data={data.editDTO} onChange={() => { }} />}
    />
}

export default DrawingTestDiff;