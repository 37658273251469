import { useI18n } from "../../../utils/lni18n";
import DOMPurify from 'dompurify';
import { BaseTestDataDTO } from "../DomainModels";

const DiffTestGeneral = (props: { data: BaseTestDataDTO}) => {

    
  const { languageService: t } = useI18n();
  const { data } = props;

  return (
    <>
      <div className="pt-3 mb-5">
        <div className="row">
          <div className="col-lg-6">
            <span className="font-weight-bold">
              {t.getText("name")}
            </span>
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.Name)}} />

          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-3 mb-lg-0 mt-5 d-flex flex-col" >
            <div className="mt-auto">
              <label className="font-weight-bold preserve-white">
                {t.getText("extra.instructions.to.students")}
              </label>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.Instructions)}} ></div>
              
            </div>

          </div>
          <div className="col-lg-6 mt-5 d-flex flex-col">
            <div className="">
              <label className="font-weight-bold preserve-white">
                {t.getText("guidelines")}
              </label>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.Meta)}} ></div>
            </div>
          </div>

        </div>
      </div>
      
    </>
  )

}

export default DiffTestGeneral;

