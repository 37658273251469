import { useEffect } from "react";
import classes from '../tests.module.scss';
import { useState } from "react";
import { LnCollapseGroup } from "src/components/LnCard/LnCollapseGroup";
import { useI18n } from "src/utils/lni18n";
import { TestDtoDiff } from "src/coursepart/domain/TestsDiffResponse";
import TestLinkBodyDiff from "./TestLinkBodyDiff";
import { Outlet } from "react-router-dom";

const DiffTests = (props: { linkToDiff?: boolean, tests: TestDtoDiff[] }) => {

    const { languageService: t } = useI18n();
    const { tests, linkToDiff } = props;

    const [connectedTests, setConnectedTests] = useState<TestDtoDiff[] | undefined>(undefined);
    useEffect(() => {
        setConnectedTests(tests.filter(t => !!t.ConnectedLecture).map(t => {
            return { ...t }
        }));
    }, [tests]);

    const [nonConnectedTests, setNonConnectedTests] = useState<TestDtoDiff[] | undefined>(undefined);

    useEffect(() => {
        setNonConnectedTests(tests.filter(t => !t.ConnectedLecture).map(t => {
            return { ...t }
        }));
    }, [tests]);


    return <>

       

        <div >

            <div className="max-col-width">

                {connectedTests && connectedTests.length > 0 &&
                    <div className="mb-4">
                        <LnCollapseGroup groupName={t.getText("tests.for.study.in.lecture")} isOpen={false} >
                            {connectedTests.map((test) => (
                                <TestLinkBodyDiff linkToDiff={linkToDiff} test={test} key={test.Id} />
                            ))}
                        </LnCollapseGroup>
                    </div>
                }


                <div className="mt-3">

                    {nonConnectedTests &&
                        <div className="flex-column">
                            {nonConnectedTests.map(test =>
                                <TestLinkBodyDiff linkToDiff={linkToDiff} test={test} key={test.Id} />
                            )}
                        </div>

                    }
                </div>



            </div>
        </div>




    </>

}

export default DiffTests;
