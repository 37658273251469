import { DiffTestDataDTO } from "../../DomainModels";
import FileTest from "../../FileTest/FileTest";
import TestDiffByReadOnly from "../TestDiffByReadOnly";


const FileTestDiff = (props: { data: DiffTestDataDTO }) => {

    const { data } = props;
    if (!data) return null;

    return <TestDiffByReadOnly data={data}
        publComponent={<FileTest readOnly={true} data={data.publDTO} onChange={() => { }} />}
        editComponent={<FileTest readOnly={true} data={data.editDTO} onChange={() => { }} />}
    />
}

export default FileTestDiff;