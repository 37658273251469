
import coursePartService, { onePartatom } from '../CoursePartService';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Outlet, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import WaitRipple from 'src/components/WaitRipple';
import classes from "../CoursePart.module.scss";
import CoursePartLoadError from '../CoursePartLoadError';
import { useI18n } from 'src/utils/lni18n';
import LnIcon from 'src/components/LnIcon';
import { LockReason } from 'src/types';
import LockedReason from 'src/components/LockedReason';

export default function ReadOnlyCoursePart() {

    const { partId } = useParams();

    const [partData] = useAtom(
        useMemo(() => onePartatom(partId || ""), [partId]));

    const { languageService: t } = useI18n();


    if (!partData || partData.Loading) {
        return <WaitRipple />
    }

    if (partData.Error) {
        return <CoursePartLoadError />
    }

    const part = partData.Data!;

    const setActive = ({ isActive, isPending }: { isActive: boolean, isPending: boolean }) => isActive ? "active" : "";

    const unlock = () => {
        coursePartService.loadPart({ partId: part.Id, forceReload: true, forceUnlock: true, openFromCourse: partData.OpenFromcourse })
    }

    let openAgainButton = null;

    if (part.Locked!.Reason === LockReason.LockedBySelf) {
        openAgainButton = <button className='mt-3 ml-3 btn btn-warning btn-small' onClick={unlock} >{t.getText("yes")}</button>;
    }

    return <>

        <div className={"scrollContent scroller "} aria-live="polite">
            <div className="card-box big-card-box">
                <div className="card-box-title card-title ">
                    <div className='w-100 d-flex align-items-start'>
                        <h3 className='mr-2'>{part?.Name}<LnIcon className='ml-3' name="locked" /></h3>
                        <div className='ml-auto'>
                            <LockedReason info={part!.Locked!} >{openAgainButton}</LockedReason>
                        </div>


                    </div>
                </div>
                <div className="card-box-content card-body">
                    <div className="mb-5 d-print-none">
                        <div className="custom-tab-heading-list" >

                            <NavLink className={setActive} to="texts" state={{ readOnly: true }} >{t.getText("introtext")}</NavLink>
                            <NavLink className={setActive} to="lectures" state={{ readOnly: true }} >{t.getText("lectures")}</NavLink>
                            <NavLink className={setActive} to="files" state={{ readOnly: true }} >{t.getText("files")}</NavLink>
                            <NavLink className={setActive} to="links" state={{ readOnly: true }} >{t.getText("links")}</NavLink>
                            <NavLink className={setActive} to="tests" state={{ readOnly: true }} >{t.getText("tests")}</NavLink>
                            <NavLink className={setActive} to="evaluations" state={{ readOnly: true }} >{t.getText("evaluations")}</NavLink>

                            <div className={classes.spacer}>&nbsp;</div>

                            <NavLink className={setActive} to="general" state={{ readOnly: true }} >{t.getText("general")}</NavLink>
                            <NavLink className={setActive} to="print" >{t.getText("print")}</NavLink>

                            <NavLink className={setActive} to="publish" >
                                {t.getText("publish")}
                                {partData?.Data?.Dirty && <LnIcon name="alert" className='icon-small ml-2' />}
                            </NavLink>



                        </div>
                    </div>
                    <main className="">
                        <Outlet />
                    </main>
                </div>
            </div>
        </div>
    </>



}