import { useAtom } from "jotai";
import { backGroundUploads } from "../FileUpload/FileUploadService";
import classes from "./FileUploadPanel.module.scss";
import { Alert, Card, CardBody, CardHeader, CardTitle, Progress } from "reactstrap";
import { useI18n } from "src/utils/lni18n";
import LnIcon from "../LnIcon";
import { useEffect, useRef } from "react";

const FileUploadPanel = () => {

    const [state] = useAtom(backGroundUploads);

    const dialog = useRef<HTMLDialogElement | null>(null);

    const { languageService: t } = useI18n();

    useEffect(() => {
        if (dialog.current) {
            if( state && state.length > 0) {
                dialog.current.show();
            }
            else {
                dialog.current.close();
            }
        }
    }, [state]);


    return (
        <dialog ref={dialog} className={classes.fileUploadPanel} >
            <div data-dialog-draggable className="moveable alert alert-primary">{t.getText("uploads")}</div>
            {state.map(b => (

                <Card key={b.id} className={"mb-3 border" + (b.errorMess ? " border-danger" : "")} >
                    <CardHeader>
                        <CardTitle>
                            {b.killer && <button onClick={() => b.killer!()} type="button" className="close round">
                                <LnIcon name="close" className="icon-small" />
                            </button>}
                            {b.backGroundMessage}

                            {b.errorMess && <Alert className="mt-3" color="danger">{b.errorMess.toString()}</Alert>}
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Progress value={b.percentUploaded} max="100" />
                    </CardBody>
                </Card>
            ))}
        </dialog>
    )


}

export default FileUploadPanel;
