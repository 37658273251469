import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useI18n } from 'src/utils/lni18n';
import { Card, CardBody, CardHeader } from 'reactstrap';

import courseService, { oneCourseatom } from 'src/course/CourseService';
import { useCallback, useMemo, useState } from 'react';
import { CourseEditResponse, TinyTheme } from 'src/course/domain/CourseEditModel';
import classes from "../SideBar.module.scss";

import { Link } from 'react-router-dom';

import { useDialog } from 'src/components/Modalservice/Dialogservice';
import LnIcon from 'src/components/LnIcon';
import ThemeCard from './ThemeCard';

import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, defaultDropAnimationSideEffects, useSensor, useSensors, } from "@dnd-kit/core";
import type { Active, DragEndEvent, DragOverEvent, DropAnimation, Over, UniqueIdentifier } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, } from "@dnd-kit/sortable";
import { useImmerAtom } from 'jotai-immer';
import { arrayMoveMutate } from 'src/utils/ArrayMethods';
import { createPortal } from 'react-dom';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import PartCard from './PartCard';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from 'src/components/Modalservice/ConfirmationDialog';
import { urlService } from 'src/services/NavService';
import coursePartService from 'src/coursepart/CoursePartService';

const dummyTheme: TinyTheme = {
    BehaviourType: 0,
    Id: "string",
    Name: "string",
    Order: 0,
    NewID: null,
    Parts: []
}

export default function CoursesTree(props: { course: CourseEditResponse }) {
    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const navigate = useNavigate();
    const params = useParams();
    const { themeId, partId, courseId } = params;
    const currentCourseId = props.course.Course.Id;
    const location = useLocation();

    const selectedItem = useCallback((code: string) => {

        if (!courseId) return "";

        if (!themeId) {
            return location.pathname.indexOf(code) > -1 ? "selected" : "";
        }

        if (themeId) {
            if (!partId) {
                return code === themeId ? "selected" : "";
            }
            else {
                return code === partId ? "selected" : "";
            }
        }
    }, [themeId, partId, location]);

    const inAContainer = useCallback((id: UniqueIdentifier) => {
        return !!props.course.Course.Themes.find(theme => theme.Id === id);
    }, [props.course.Course.Themes]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const dropAnimation: DropAnimation = {
        sideEffects: defaultDropAnimationSideEffects({
            styles: {
                active: {
                    opacity: '0.5',
                },
            },
        }),
    };

    const allThemes = "allThemes";

    const [active, setActive] = useState<Active | null>(null);

    const [courseData, setCourseDataFunction] = useImmerAtom(useMemo(() => oneCourseatom(courseId || ""), [courseId]));

    const dragOver = (e: DragOverEvent) => {
        const { active, over } = e;
        if (!over) {
            return;
        }
        if (active.data.current?.sortable.containerId === allThemes
            && active.data.current?.sortable.index !== undefined
            && over.data.current?.sortable.index !== undefined) {
            setCourseDataFunction(courseData => {
                if (courseData.Course.Themes) {
                    arrayMoveMutate(courseData.Course.Themes, active.data.current!.sortable.index, over.data.current!.sortable.index);
                }
            });
            return;
        }

        if (e.active.data.current?.sortable.containerId !== e.over?.data.current?.sortable.containerId) {

            // part moved to a new theme

            setCourseDataFunction(courseData => {
                if (courseData.Course.Themes) {
                    const ths = courseData.Course.Themes;
                    const newTheme = ths.find(t => t.Id === e.over?.data.current?.sortable.containerId);
                    const oldTheme = ths.find(t => t.Id === e.active?.data.current?.sortable.containerId);
                    const part = oldTheme?.Parts.find(p => p.Id === e.active.id);

                    if (part && oldTheme && newTheme) {
                        oldTheme.Parts = oldTheme.Parts.filter(p => p.Id !== e.active.id);
                        newTheme.Parts.splice(e.over?.data.current?.sortable.index, 0, part);
                    }

                }
            });
        }
        else {  // part moved within theme
            setCourseDataFunction(courseData => {
                if (courseData.Course.Themes) {
                    const ths = courseData.Course.Themes;
                    const theme = ths.find(t => t.Id === e.active?.data.current?.sortable.containerId);
                    if (theme) {
                        arrayMoveMutate(theme.Parts, e.active.data.current?.sortable.index, e.over?.data.current?.sortable.index);
                    }
                }
            });
        }
    }

    const dragEnd = async (ev: DragEndEvent) => {
        setActive(null);
        courseService.saveCourse(courseId!);
    };

    const getThemeById = useCallback((id: UniqueIdentifier) => {
        return props.course.Course.Themes?.find(th => th.Id === id)!;
    }, [props.course.Course.Themes]);

    const getPartById = useCallback((id: UniqueIdentifier) => {
        let part: any = undefined;
        props.course.Course.Themes?.forEach(th => {
            const p = th.Parts.find(p => p.Id === id);
            if (p) part = p;
        });

        return part;

    }, [props.course.Course.Themes]);


    async function closeCourse(course: CourseEditResponse) {

        if (course.Locked || !course?.IsDirty) {

            if (partId) {
                const result = await coursePartService.checkIfPartCanClose(partId);
                if (result) {
                    navigate(urlService.urlToPartInCourse(currentCourseId, themeId!, partId, "publish"));
                }
            }
            else {
                doCloseCourse(course.Course.Id);
                return;
            }
        }

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: `${t.getText("course")}: ${course.Course.Name}`,
                    message: <div className="preserve-white" >{t.getText("publish.before.close")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then(async (res: boolean) => {
            if (res) {
                navigate(urlService.urlToCourse(courseId!, "publish"));
            }
            else {
                doCloseCourse(course.Course.Id);
            }
        });


    }

    async function doCloseCourse(courseId: string) {
        const nextCourseId = await courseService.closeCourse(courseId);

        window.setTimeout(() => {
            if (nextCourseId) {
                navigate(urlService.urlToCourse(nextCourseId));
            }
            else {
                navigate(`/`);
            }
        }, 100);
    }


    const navigateToCourseFeature = async (feature: string) => {
        navigate(urlService.urlToCourse(currentCourseId, feature));
        return;
    };

    const readOnly = !!props.course.Locked;

    return (
        <div className='mb-3'>
            <Card className={[classes.theme, "border border-primary"].join(' ')}>
                <CardHeader className={`${classes.header} d-flex pr-1 align-items-center`}>
                    <LnIcon name="eye" className='mr-3' />
                    <strong>{props.course.Course.Name}</strong>
                    {readOnly && <LnIcon name="locked" className='icon-small ml-3 mt-1' />}
                    <div className="ml-auto">
                        {props.course.IsDirty && <LnIcon name="alert" className='icon-small mb-2 mr-2' />}

                    </div>



                </CardHeader>
                <CardBody className={classes.parts}>

                    <div onClick={() => navigateToCourseFeature("general")} className={[selectedItem("general"), classes.part, "w-100"].join(' ')}>
                        <div className={classes.partname}>{t.getText("general")}</div>
                    </div>

                    <div onClick={() => navigateToCourseFeature("publish")} className={[selectedItem("publish"), classes.part, "w-100"].join(' ')}>
                        <div className={classes.partname}>{t.getText("publish")}</div>
                    </div>

                    <div onClick={() => navigateToCourseFeature("addons")} className={[selectedItem("addons"), classes.part, "w-100"].join(' ')}>
                        <div className={classes.partname}>{t.getText("courseaddons")}</div>
                    </div>

                    <div onClick={() => navigateToCourseFeature("diploma")} className={[selectedItem("diploma"), classes.part, "w-100"].join(' ')}>
                        <div className={classes.partname}>{t.getText("diploma")}</div>
                    </div>

                    <div onClick={() => navigateToCourseFeature("print")} className={[selectedItem("print"), classes.part, "w-100"].join(' ')}>
                        <div className={classes.partname}>{t.getText("print")}</div>
                    </div>

                    <div className={[classes.part, "mt-3 w-100"].join(' ')}>
                        <button onClick={(e) => closeCourse(props.course)} className="btn btn-link" type="button">
                            <div className={classes.partname}>{t.getText("close")}</div>
                        </button>
                    </div>



                </CardBody>
            </Card >

            <DndContext sensors={sensors}

                onDragStart={({ active }) => { setActive(active); }}
                onDragOver={dragOver} onDragEnd={dragEnd} onDragCancel={() => { setActive(null); }}>

                <div role="list" className='position-relative'>
                    <SortableContext items={props.course.Course.Themes.map(l => l.Id)} id={allThemes}>
                        {props.course.Course.Themes.map(t => <ThemeCard readOnly={readOnly} key={t.Id} themeId={themeId} currentCourseId={currentCourseId} theme={t} partId={partId} />)}
                    </SortableContext>
                </div>

                {createPortal(
                    <DragOverlay adjustScale={false} dropAnimation={dropAnimation} modifiers={[restrictToVerticalAxis]} >

                        {active
                            ? inAContainer(active.id)
                                ? <ThemeCard readOnly={true} themeId={themeId} dragged={true} currentCourseId={currentCourseId} theme={getThemeById(active.id)} partId={partId} />
                                : <PartCard readOnly={true} dragged={true} part={getPartById(active.id)} partId={partId} theme={dummyTheme} currentCourseId={currentCourseId} />
                            : null}
                    </DragOverlay>,
                    document.body
                )}


            </DndContext>

        </div>
    )
}
