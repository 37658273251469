import { useI18n } from "src/utils/lni18n";
import HtmlTextEditor from 'src/components/TextEditor/HtmlTextEditor';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DrawingTestDataDTO, EditTestDataDTO } from '../DomainModels';
import { useAtom } from "jotai";
import { HexColorPicker } from "react-colorful";
import { Collapse } from "reactstrap";
import classes from "../tests.module.scss";
import { DrawingState, DrawingTestService, hexFromColorData } from "./DrawingTestService";
import DrawingTestCanvas from "./DrawingTestCanvas";
import { CanvasService } from "./CanvasService";
import imagePickerService from "src/components/ImagePicker/ImagePickerService";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import ImagePickerDialog from "src/components/ImagePicker/ImagePickerDialog";
import { PickedImageData } from "src/components/ImagePicker/ImagePicker";
import ReadOnlyDrawingTestCanvas from "./ReadOnlyDrawingTestCanvas";


const DrawingTest = (props: { readOnly?: boolean, data: EditTestDataDTO<DrawingTestDataDTO>, onChange: (d: DrawingTestDataDTO) => void }) => {


  const dialogPortal = useDialog();
  const { data, onChange,readOnly } = props;
  const dataService = useMemo(() => new DrawingTestService(data.TestData), []);
  const drawState: DrawingState = {
    AuthColor: hexFromColorData(data.TestData.DrawingtestAdminData.AuthorColor),
    StudColor: hexFromColorData(data.TestData.DrawingtestAdminData.StudentColor),
    CurrentColor: hexFromColorData(data.TestData.DrawingtestAdminData.StudentColor),
    Points: data.TestData.DrawingtestAdminData.SplinePoints,
    Closed: data.TestData.DrawingtestAdminData.SplinePoints.length > 0,
    DrawHandles: false,
    Selected: undefined
  }

  const saveData = useCallback((value: DrawingTestDataDTO) => {

    if (!value.DrawingtestAdminData.SplinePoints || value.DrawingtestAdminData.SplinePoints.length === 0) {
      return;
    }
    onChange(value);
  }, []);


  const updateCanvasData = useCallback((value: DrawingState) => {
    if (!value.Closed || !value.Points || value.Points.length === 0) {
      return;
    }

    const data = dataService.UpdateDrawingData(value);
    saveData(data);
  }, []);

  const canvasService = useMemo(() => new CanvasService(drawState, updateCanvasData), []);

  const [testData] = useAtom(dataService.dataAtom);
  const { languageService: t } = useI18n();

  const [drawingData] = useAtom(canvasService.drawingStateAtom);
  const { AuthColor, StudColor } = drawingData;

  const [openAuthColor, setOpenAuthColor] = useState(false);
  const [openStudColor, setopenStudColor] = useState(false);

  const toggleAuth = () => {

    if (openStudColor) {
      toggleStud()
    }
    if (!openAuthColor) {
      canvasService.setCurrentColor(AuthColor);
    }
    else {
      canvasService.setCurrentColor(StudColor);
    }

    setOpenAuthColor(!openAuthColor);
  }
  const toggleStud = () => {
    if (openAuthColor) {
      toggleAuth();
    }
    setopenStudColor(!openStudColor);
  }

  const onChangeQuestion = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const data = dataService.updateQuestionText(e.currentTarget.value);
    saveData(data);
  }

  const setStudColor = (val: string) => {
    if (openStudColor) {
      canvasService.setCurrentColor(val);
    }
    canvasService.setStudColor(val);
  }

  const setAuthColor = (val: string) => {
    if (!openStudColor) {
      canvasService.setCurrentColor(val);
    }
    canvasService.setAuthColor(val);
  }

  const onSetCorrectPercent = (e: ChangeEvent<HTMLSelectElement>) => {
    const val = parseInt(e.currentTarget.value, 10)
    const data = dataService.setCorrectPercent(val)
    saveData(data);
  }

  const onSetExplainRequired = (e: ChangeEvent<HTMLInputElement>) => {
    const data = dataService.setExplainRquired(e.currentTarget.checked);
    saveData(data);
  }

  const pickImage = () => {
    imagePickerService.fetchImageData();

    dialogPortal({
      factory: (onSubmit, onCancel) => {
        return <ImagePickerDialog onCancel={onCancel} onSubmit={onSubmit} />
      },
      size: "xl"
    }).then((imageData: PickedImageData) => {
      if (imageData && imageData.imageId) {
        const data = dataService.setImageId(imageData.imageId);
        saveData(data);
      }
    });

  }

  if (!data) return null;

  return <div className="mt-5">
    <div className='mb-2'>
      <strong>{t.getText("question")}</strong>
      {testData.QuestionError && <span className="ml-4 text-danger small font-weight-bold">{testData.QuestionError}</span>}

    </div>

    <HtmlTextEditor readOnly={readOnly} value={""} onChange={onChangeQuestion} initialValue={data.TestData.Question} name="Question"  ></HtmlTextEditor>

    <div className="d-flex mt-5 align-items-center">
      <div className="mr-5">
        <button disabled={readOnly} type="button" onClick={pickImage} className="btn btn-primary btn-small">{t.getText("pick.image")}</button>
      </div>

      <div className="ml-5">
        <div>
          <strong>{t.getText("percentage.required")}</strong>
          <select disabled={readOnly} className="ml-3" value={testData.RequiredCorrectness} onChange={onSetCorrectPercent} >
            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90].map(i => <option key={i} value={i} >{i}</option>)}
          </select>
        </div>

        <div className="mt-3">
          <label className="pointer">
            <strong>{t.getText("drawingtestadmin.explain.required")}</strong>
            <input disabled={readOnly} type="checkbox" className="ml-3" checked={testData.RequireUserComment} onChange={onSetExplainRequired} value="true" />

          </label>
        </div>
      </div>

    </div>

    <div className="mt-5 row">
      <div className="col-6">
        <div className='mb-2 font-weight-bold'>
          {t.getText("student.color")}
          <button disabled={readOnly} className="noFocus ml-4 btn btn-link" type="button" onClick={toggleStud} >
            <span className={classes.colorCircle} style={{ backgroundColor: StudColor }} >&nbsp;</span>
          </button>
        </div>
        {!readOnly &&
          <Collapse className="position-absolute" isOpen={openStudColor}>
            <HexColorPicker color={StudColor} onChange={setStudColor} />
          </Collapse>
        }
      </div>

      <div className="col-6">
        <div className='mb-2 font-weight-bold'>
          {t.getText("author.color")}
          <button disabled={readOnly} className="noFocus ml-4 btn btn-link" type="button" onClick={toggleAuth} >
            <span className={classes.colorCircle} style={{ backgroundColor: AuthColor }} >&nbsp;</span>
          </button>
        </div>
        {!readOnly &&
          <Collapse className="position-absolute" isOpen={openAuthColor}>
            <HexColorPicker color={AuthColor} onChange={setAuthColor} />
          </Collapse>
        }
      </div>


    </div>

    <div className="mt-5">
      {readOnly && <ReadOnlyDrawingTestCanvas canvasService={canvasService} fileUrl={testData.ServerImageUrl} state={drawingData} ></ReadOnlyDrawingTestCanvas>}
      {!readOnly && <DrawingTestCanvas canvasService={canvasService} fileUrl={testData.ServerImageUrl} state={drawingData} ></DrawingTestCanvas>}
    </div>



  </div>

}

export default DrawingTest;



