import { LanguageService } from "src/utils/languageService";
import {  Editor as TinyMCEEditor } from 'tinymce';

export const getEditorSettings = (toolbarName: string, t: LanguageService)=>{

    var addFieldsMenu = (editor:TinyMCEEditor) => {

        editor.ui.registry.addMenuButton('template', {
            text: t.getText("template.items"),
            fetch: (callback) => {
              const items: any[] = [
                {
                  type: 'menuitem',
                  text: t.getText("date"),
                  onAction: () => editor.insertContent('<span contenteditable="false" data-item-template="date" >[' + t.getText("date") + ']</span>')
                },
                {
                  type: 'menuitem',
                  text: t.getText("full.name"),
                  onAction: () => editor.insertContent('<span contenteditable="false" data-item-template="fullname" >[' + t.getText("full.name") + ']</span>')
                },
                {
                    type: 'menuitem',
                    text: t.getText("personal.identity.number"),
                    onAction: () => editor.insertContent('<span contenteditable="false" data-item-template="identity_number" >[' + t.getText("personal.identity.number") + ']</span>')
                  }
              ];
              callback(items);
            }
          });
    }

    const fonts = ["Andale Mono=andale mono,times",
        "Arial=arial,helvetica,sans-serif",
        "Arial Black=arial black,avant garde",
        "Book Antiqua=book antiqua,palatino",
        "Comic Sans MS=comic sans ms,sans-serif",
        "Courier New=courier new,courier",
        "Georgia=georgia,palatino",
        "Helvetica=helvetica",
        "Impact=impact,chicago",
        "Nothing You Could Do=Nothing You Could Do",
        "PT Sans=pt sans",
        "Symbol=symbol",
        "Tahoma=tahoma,arial,helvetica,sans-serif",
        "Terminal=terminal,monaco",
        "Times New Roman=times new roman,times",
        "Trebuchet MS=trebuchet ms,geneva",
        "Verdana=verdana,geneva",
        "Webdings=webdings",
        "Wingdings=wingdings,zapf dingbats"].join("; ");

        var fontsizes = [];
        for (var i = 0; i < 53; i++) {
            fontsizes.push( (6+i*2).toString() + "pt" );
        }

        return {
            fixed_toolbar_container: "#" + toolbarName,
            lineheight_formats: '80% 85% 90% 100% 110% 120% 130% 140% 150% 160% 170% 180% 190% 200% 210% 220% 230% 240%',
            toolbar: ['undo redo | fontfamily fontsize forecolor lineheight | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | indent outdent | link image | template'],
            font_family_formats: fonts,
            font_size_formats: fontsizes.join(" "),
            menubar: "edit table format tools",
            customSetup: addFieldsMenu
    
        };


}