import * as React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LanguageService } from "../../utils/languageService";
import LnIcon from "src/components/LnIcon";
import { DiplomaText } from "./Diploma";

export interface DiplomaSettingsOptions {
    languageService: LanguageService;
    onSubmit: (d: any) => void;
    props: DiplomaText,
    setter: React.Dispatch<React.SetStateAction<DiplomaText>>
}

const nudgespeed = 30;

export const DiplomaSettingsDialog: React.FC<DiplomaSettingsOptions> = ({
    onSubmit,
    languageService: t,
    setter,
    props
}) => {


    const [timerId, setTimerId] = React.useState<number | undefined>(undefined);
    const [state, setState] = React.useState<DiplomaText>(structuredClone(props));

    const setValue = (action: (d: DiplomaText) => DiplomaText) => {
        setter(d => action(structuredClone(d)));
        setState(d => action(structuredClone(d)));
    }


    const move = (arg: string) => {
        const directions = {
            up: { property: 'Top', delta: -1 },
            down: { property: 'Top', delta: 1 },
            left: { property: 'Left', delta: -1 },
            right: { property: 'Left', delta: 1 }
        };
        const direction = directions[arg as keyof typeof directions];
        if (!direction) return;
        setValue(d => {
            if (d.BgImage) {
                try {
                    const prop = direction.property as keyof typeof d.BgImage;
                    (d.BgImage[prop] as number) += direction.delta;
                } catch (e) {
                    console.log(e);
                }
            }
            return d;
        });
    };


    const clearBg = () => {
        setValue(d => {
            if (d.BgImage) {
                d.BgImage = { ImageId: "", Left: 0, Top: 0, Size: 0, Src: "" };
            }
            return d;
        });
    }



    const startMove = React.useCallback((arg: string) => {
        move(arg);
        clearInterval(timerId);
        const a = arg;
        const _m = move;
        const tid = setInterval(function () {
            _m(a);
        }, nudgespeed);
        setTimerId(tid);


    }, [timerId]);

    const endMove = React.useCallback(() => {
        const t = timerId;
        setTimerId(undefined);
        clearInterval(t);
    }, [timerId]);


    const setSize = (e: React.ChangeEvent<HTMLInputElement>) => {

        const val = parseInt(e.currentTarget.value, 10)
        setValue(d => {
            if (d.BgImage) {
                d.BgImage.Size = val;
            }

            return d;
        })

    }

    const setPadding = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const val = parseInt(e.currentTarget.value, 10);

        setValue(d => {
            switch (name) {
                case "top":
                    d.Padding.Top = val;
                    break;

                case "sides":
                    d.Padding.Sides = val;
                    break;

                case "bottom":
                    d.Padding.Bottom = val;
                    break;
            }
            return d;
        });
    }

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("properties")}</ModalHeader>
        </span>
        <ModalBody>

            <h4>{t.getText("background")}</h4>
            <div>{t.getText("position")}</div>

            <div className="text-center">
                <button type="button" className="btn btn-link" onMouseUp={endMove} onMouseOut={endMove} onMouseDown={() => startMove('up')}>
                    <LnIcon name="next-2" className="rotate270" />

                </button>

                <br />
                <button type="button" className="btn btn-link" onMouseUp={endMove} onMouseLeave={endMove} onMouseDown={() => startMove('left')}>
                    <LnIcon name="next-2" className="rotate180" />
                </button>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <button type="button" className="btn btn-link" onMouseUp={endMove} onMouseLeave={endMove} onMouseDown={() => startMove('right')}>
                    <LnIcon name="next-2" className="" />
                </button>


                <br />
                <button type="button" className="btn btn-link" onMouseUp={endMove} onMouseLeave={endMove} onMouseDown={() => startMove('down')}>
                    <LnIcon name="next-2" className="rotate90" />
                </button>

            </div>



            <div className="mb-3">
                <div>{t.getText("size")}</div>
                <div className="d-flex align-items-center">
                    <input value={state.BgImage?.Size} onChange={setSize} type="range" min="10" max="200" step={1.0} />
                    <span className="ml-3">%</span>
                </div>
            </div>

            <button type="button" className="btn btn-warning btn-small mb-3" onClick={clearBg}>
                {t.getText("none")}
            </button>

            <hr />

            <h4 className="mt-4">{t.getText("margins")}</h4>

            <div>{t.getText("top")}</div>
            <input value={state.Padding.Top} onChange={setPadding} name="top" type="number" min="0" max="100" step="1" /> mm

            <div className="mt-3">{t.getText("left")}  {t.getText("right")}</div>
            <input value={state.Padding.Sides} onChange={setPadding} name="sides" type="number" min="0" max="100" step="1" /> mm

            <div className="mt-3">{t.getText("bottom")}</div>
            <input value={state.Padding.Bottom} onChange={setPadding} name="bottom" type="number" min="0" max="100" step="1" /> mm



        </ModalBody >
        <ModalFooter>
            <Button type="button" className="btn btn-primary btn-small" onClick={() => onSubmit(true)}>{t.getText("close")}</Button>
        </ModalFooter>
    </>
};
