import { useMemo } from "react";
import { useI18n } from "src/utils/lni18n";
import { Outlet, useParams } from "react-router-dom";
import { onePartatom } from "src/coursepart/CoursePartService";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { ChangedColorLegendThreeStates, ChangedColorLegendWrap } from "src/components/ChangedColorLegend";
import DiffTests from "./DiffTests";

const TestDiffMain = () => {
    const { partId } = useParams();
    const { languageService: t } = useI18n();
    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const testsDiffs = partData?.DiffTests;

    if (!testsDiffs) return null;



    return <>

        <Outlet ></Outlet>

        <div className="d-flex mb-5 ">

            <h3 className="flex-fill">{t.getText("tests")}</h3>
        </div>


        <div className="d-flex">
            <Link to={"../tests"} className='ml-auto btn btn-inverse btn-small mb-4' >{`${t.getText("close")} ${t.getText("differences")}`}</Link>
        </div>


        <div className="row">

            <div className="col-md-6">
                <ChangedColorLegendThreeStates />

                <h4 className="mb-2">{t.getText("published")}</h4>
                <div className="pl-4">
                    <DiffTests tests={testsDiffs.Published} />
                </div>
            </div>

            <div className="col-md-6">
                <ChangedColorLegendWrap></ChangedColorLegendWrap>
                <h4 className="mb-2">{t.getText("edited")}</h4>
                <div className="pl-4">
                    <DiffTests linkToDiff={true} tests={testsDiffs.Edited} />
                </div>
            </div>
        </div >


    </>

}

export default TestDiffMain;
