import { DiffTestDataDTO } from "../DomainModels";
import { TestType } from "src/coursepart/domain/TestTypeInfoDTO";
import QADiff from "./QA/QADiff";
import DrawingTestDiff from "./Drawing/DrawingTestDiff";
import MCTestDiff from "./MCTest/MCTestDiff";
import FileTestDiff from "./FileTest/FileTestDiff";

const DiffTestComponent = (props: { data: DiffTestDataDTO }) => {

    const { data } = props;

    let component = null;

    switch (data.editDTO.Type) {

        case TestType.selfassessement:
        case TestType.simpleselfassessement:
        case TestType.singlequestion:
        case TestType.multiplequestion:
        case TestType.simplyreply:
            component = <QADiff data={data} />
            break;

        case TestType.drawingtest:
            component = <DrawingTestDiff data={data} />
            break;

        case TestType.multiplechoice2:
            component = <MCTestDiff data={data} />
            break;

        case TestType.fileupload:
            component = <FileTestDiff data={data} />
            break;


        default:
            break;
    }

    return (component || <div></div>)

}

export default DiffTestComponent;

