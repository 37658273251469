
import { useI18n } from 'src/utils/lni18n';
import classes from "./eval.module.scss";
import { EvaluationQuestionDTO } from '../domain/EvaluationEditDataDTO';
import { useState } from 'react';
import EvalEditor from './EvalEditor';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { DragHandle } from 'src/components/DragHandle/DragHandle';
import { CSS } from '@dnd-kit/utilities';
import LnIcon from 'src/components/LnIcon';
import DOMPurify from 'dompurify';

export default function EvalQuestionReadOnly(props: { readOnly?: boolean, partId: string, 
    onDelete?: (q: EvaluationQuestionDTO) => void,
    q: EvaluationQuestionDTO, num: number, active?: UniqueIdentifier, dragged?: boolean }) {

    const { languageService: t } = useI18n();
    const { q, num, dragged, readOnly } = props;
    const [edit, setEdit] = useState(false);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: q?.Id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };

    const deleteQuestion = (): void => {
        if (props.onDelete) {
            props.onDelete(q);
        }
    }


    return <div ref={setNodeRef} style={style} className={classes.question + " max-col-width"} key={q.Id} >
        <div className='alert alert-grey d-flex align-content-start'>
            <div className='pointer' onClick={() => setEdit(e => !e)}>
                <strong>{t.getText("question")}&nbsp;{(num + 1)}</strong>
            </div>
            <div className="flex-fill ml-3 pointer" onClick={() => setEdit(e => !e)} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(q.QuestionText) }}></div>

            <div className='d-flex align-items-center'>
                <button className='pt-3 px-3 btn btn-link' onClick={() => setEdit(e => !e)}>
                    <span className={`${edit ? "dark-caret-point-left" : "dark-caret"}`}></span>
                </button>

                <span className='' >
                    <LnIcon name="locked" className="icon-small" />
                </span>
                
                {!readOnly &&
                    <button className='pt-2 btn btn-link ml-3' onClick={() => deleteQuestion()}>
                        <LnIcon name="delete" className="icon-small" />
                    </button>
                }

                <div className='mt-1 ml-2' {...attributes} {...listeners}><DragHandle /></div>
            </div>

        </div>
        {edit &&
            <div className="ml-3">
                <EvalEditor locked={q.Locked || readOnly || false} qId={q.Id} />
            </div>
        }

    </div>



}