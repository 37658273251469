
import { useI18n } from 'src/utils/lni18n';
import classes from "./eval.module.scss";
import { EvaluationQuestionDTO } from '../domain/EvaluationEditDataDTO';
import { useCallback, useEffect, useState } from 'react';
import HtmlTextEditor from 'src/components/TextEditor/HtmlTextEditor';
import EvalEditor from './EvalEditor';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { DragHandle } from 'src/components/DragHandle/DragHandle';
import { CSS } from '@dnd-kit/utilities';
import LnIcon from 'src/components/LnIcon';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from 'src/components/Modalservice/ConfirmationDialog';
import coursePartService from '../CoursePartService';
import DOMPurify from 'dompurify';

export default function EvalQuestion(props: {
    partId: string, q: EvaluationQuestionDTO,
    onDelete: (q: EvaluationQuestionDTO) => void,
    num: number, active?: UniqueIdentifier, dragged?: boolean
}) {

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const { q, num, active, dragged, partId } = props;
    const [edit, setEdit] = useState(false);
    const [invalidText, setInvalidText] = useState(false);
    const [initialText, setInitialText] = useState("");

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: q?.Id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };

    const setValue = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        coursePartService.saveEvalQuestionText(partId, value, q.Id);

    }, []);

    const changeEditState = useCallback(() => {
        setEdit(e => !e);
        setInitialText(q.QuestionText);
    }, [q.QuestionText]);

    useEffect(() => {
        setInitialText(q.QuestionText);
    }, [q.Id]);


    const deleteQuestion = (): void => {
        if (props.onDelete) {
            props.onDelete(q);
        }
    }

    function checkText(name: string, html: string, state?: boolean | undefined, text?: string | undefined): void {
        setInvalidText(!text || text.length === 0);
    }

    return <div ref={setNodeRef} style={style} className={classes.question + " max-col-width"} key={q.Id} >
        <div className='alert alert-grey d-flex'>
            <div className='pointer' onClick={() => changeEditState()}>
                <strong>{t.getText("question")}&nbsp;{(num + 1)}</strong>
            </div>
            {!edit &&
                <div className="flex-fill ml-3 pointer" onClick={() => changeEditState()} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(q.QuestionText) }}></div>
            }
            {edit &&
                <HtmlTextEditor invalid={invalidText} className='border flex-fill' setValue={checkText} onChange={setValue} initialValue={initialText} name="question"  ></HtmlTextEditor>
            }
            <div>
                <div className='d-flex align-items-center'>
                    <button className='pt-2 px-3 btn btn-link' onClick={() => changeEditState()}>
                        <span className={`${edit ? "dark-caret-point-left" : "dark-caret"}`}></span>
                    </button>

                    <button className='btn btn-link' onClick={() => deleteQuestion()}>
                        <LnIcon name="delete" className="icon-small" />
                    </button>

                    <div className='pb-2 ml-2' {...attributes} {...listeners}><DragHandle /></div>
                </div>
            </div>

        </div>
        {edit &&
            <div className="ml-3">
                <EvalEditor locked={q.Locked} qId={q.Id} />
            </div>
        }

    </div>



}