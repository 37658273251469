import { useI18n } from "src/utils/lni18n";
import { useMemo, useState } from 'react';
import { ImportXLFileCommand, EditTestDataDTO, MCTestDataDto } from '../DomainModels';
import { useAtom } from "jotai";
import QuestionsList from "./QuestionsList";
import { MCTestService } from "./MCTestService";
import QuestionEditor from "./QuestionEditor";
import { API_HOST } from "src/utils/constants";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import ImportXLDialog from "./ImportXLDialog";
import { useLocation } from "react-router-dom";

const MCTest = (props: { readOnly: boolean, data: EditTestDataDTO<MCTestDataDto>, onChange: (d: MCTestDataDto) => void }) => {

    const dialogPortal = useDialog();
    const { data, onChange, readOnly } = props;
    const { languageService: t } = useI18n();
    const service = useMemo(() => new MCTestService(data.TestData), []);

    const [mcData] = useAtom(service.datatom);
    const [initialData] = useAtom(service.currentQuestionInitialAtom);
    const [questionNumber, setquestionNumber] = useState<number | undefined>(undefined);

    const handleOnEdit = (index: number) => {
        service.closeCurrentQuestion();
        setquestionNumber(undefined);
        window.setTimeout(() => {
            service.setCurrentQuestion(index);
            setquestionNumber(index);
        }, 100);


    }

    const importXL = () => {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <ImportXLDialog onSubmit={onSubmit} onCancel={onCancel} />
            },
            size: "md"
        }).then((d: { key: string, replace: boolean }) => {
            const postData: ImportXLFileCommand = {
                FileName: d.key,
                PartId: data.PartID,
                TestId: data.TestId,
                Replace: d.replace
            }
            service.importFromXL(postData);
        }).catch(() => { });

    }


    const getLink = `${API_HOST}/api/author/coursepart/${data.PartID}/test/${data.TestId}/ExportToSpreadsheet`

    if (!data) return null;

    function handleOnChange(d: MCTestDataDto): void {
        onChange(d);
    }

    function handleDelete(index: number) {
        var data = service.deleteQuestion(index);
        onChange(data);
    }

    function setPercentage(percent: number) {
        var data = service.setPercentage(percent);
        onChange(data);
    }

    return <div className="mt-5" >
        <div className="row mb-3">
            <div className="col-sm-4 col-md-3 mb-3">
                <div className="d-flex">
                    <strong>
                        {t.getText("percentage.required")}
                    </strong>

                    <input disabled={readOnly} className="w-50 form-control ml-3" type="number" min={10} max={100} step={5} value={mcData.Percentage} onChange={(e) => setPercentage(+e.currentTarget.value)} />

                </div>
            </div>
            {!readOnly &&
                <div className="col-sm-8 col-md-9">
                    <button disabled={readOnly} type='button' onClick={importXL} className='btn btn-warning btn-small mr-3  mb-3' >{t.getText("import.from.xl")}</button>
                    <a target="_blank" href={getLink} className='btn btn-primary btn-small mb-3' >{t.getText("export.as.xl")}</a>
                </div>
            }
        </div>
        <div className="row">
            <div className="col-sm-4 col-md-3">
                <QuestionsList readOnly={readOnly} service={service} data={mcData.Questions} onDelete={(i) => handleDelete(i)} onEdit={(i) => handleOnEdit(i)} />
            </div>
            <div className="col-sm-8 col-md-9">
                {initialData && questionNumber !== undefined && <QuestionEditor readOnly={readOnly} questionIndex={questionNumber} service={service} data={initialData} onChange={handleOnChange} />}
            </div>

        </div>
    </div>

}

export default MCTest;

