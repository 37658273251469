//@ts-check
import { useRef, useEffect, useCallback } from "react";
import { useI18n } from "src/utils/lni18n";
import LnIcon from "src/components/LnIcon";
import WaitRipple from "src/components/WaitRipple";
import LectureControls from "./LectureControls";
import Screen from "../Screen/Screen";
import classes from './player.module.scss';
import lecturePlayerService, { ScreenJumps, currentLecturePlayingAtom, currentScreenAtom } from "./LecturePlayerService";
import KeyboardEventHandler from '@infinium/react-keyboard-event-handler';
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import screenAudioEditService from "./ScreenAudioEditService";
import recorderService from "../AudioRecorder/Recoderservice";



const LecturePlayer = () => {

    const [lectureData] = useAtom(currentLecturePlayingAtom);
    const [currentScreen] = useAtom(currentScreenAtom);
    const navigate = useNavigate();
    const lectureElement = useRef(null);
    const { languageService: t } = useI18n();


    useEffect(() => {
        // Reset any editing of data 
        screenAudioEditService.resetData();
    }, [])


    const handleKeyEvent = useCallback((key: string) => {

        //const key = event.which || event.keyCode;
        let num = -1;

        switch (key) {
            case "left":
                num = lecturePlayerService.shiftScreenNumber(ScreenJumps.prev);
                break;
            case "right":
                num = lecturePlayerService.shiftScreenNumber(ScreenJumps.next);
                break;

            case "esc":
                if (!recorderService.dialogOpen) {
                    navigate("./../../");
                }
                break;

            case "space":
                lecturePlayerService.togglePlayingState();
                break;
            default:
                break;
        }

        if (num > -1) {
            navigate(`./../../${num}/play`);
        }

    }, []);



    if (!lectureData) {
        return <div>No data</div>;
    }


    const { currentLecture, controlsState: { visible: controlsVisible } } = lectureData;

    const setControlsVisible = (e: any, state: boolean) => {
        lecturePlayerService.setVisibleControls(state);
    }


    if (!currentLecture) {
        return <WaitRipple />
    }



    return (
        <div className={classes.lecture} ref={lectureElement} >
            <KeyboardEventHandler
                handleKeys={['esc', 'left', 'right', 'space']}
                onKeyEvent={(key: string, e: any) => handleKeyEvent(key)} >
            </KeyboardEventHandler>

            <Screen currentScreen={currentScreen} lectureData={lectureData} className={classes.screen + " pointer"} />
            <LectureControls />

            {!controlsVisible &&
                <button title={t.getText("display")} className={classes.showControlsButton + " " + classes.roundButton + " btn btn-link d-flex align-items-center"} onClick={(e) => setControlsVisible(e, true)}>
                    <LnIcon className="" name="show-controls" />
                </button>

            }

            {/* {showForumDialog && <ForumThreadInLectureDialog container={lectureElement} />}
            {showTestDialog && <TestInLectureDialog container={lectureElement} />} */}


        </div >

    )
}


export default LecturePlayer;