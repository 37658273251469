import { useI18n } from "src/utils/lni18n";
import { DiffTestDataDTO } from "../DomainModels";
import DiffTestGeneral from "./DiffTestGeneral";
import React from "react";


const TestDiffByReadOnly = (props: { data: DiffTestDataDTO, publComponent: React.ReactElement, editComponent: React.ReactElement }) => {

    const { editComponent, publComponent, data } = props;
    const { languageService: t } = useI18n();

    if (!data) return null;

    return <div className="row mt-4">
        <div className="col-lg-6 border-right">
            <h3>{t.getText("published")}</h3>
            <DiffTestGeneral data={data.publDTO} />
            <hr />
            {publComponent}
        </div>
        <div className="col-lg-6">
            <h3>{t.getText("differences")}</h3>
            <DiffTestGeneral data={data.editDTO} />
            <hr />
            {editComponent}
        </div>

    </div >

}

export default TestDiffByReadOnly;