import { NavLink } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import { TestDtoDiff } from "src/coursepart/domain/TestsDiffResponse";
import classes from '../tests.module.scss';
import GetDiffStateClass from "src/utils/StateColors";

const TestLinkBodyDiff = (props: { linkToDiff?: boolean, test: TestDtoDiff }) => {

    const { test, linkToDiff } = props;
    const { languageService: t } = useI18n();
    const bgstate = GetDiffStateClass(test.State);

    return (
        <div className={[classes['test-listing'], bgstate ? "pl-4" : "pl-3" , 'py-3 my-3'].join(' ')} >
            <div className={`cardLeftIndicator ${bgstate}`}></div>
            <div>
                {!linkToDiff && test.Name}
                {linkToDiff && <NavLink to={`${test.Id}/${test.TestType}`} >{test.Name}</NavLink>}
            </div>
            {!!test.ConnectedLecture && <>
                <div className="small preserve-white">
                    {t.getText("test.connected.to.lecture", test.ConnectedLecture.Name)}
                </div>
                <NavLink state={true} to={`../lectures/${test.ConnectedLecture.Id}`} >{test.ConnectedLecture.Name}</NavLink>
            </>}
        </div>)

}

export default TestLinkBodyDiff;

