
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import courseService from "./CourseService";
import { allPartsAtom } from "src/coursepart/CoursePartCollectionService";
import { InputDialog, InputOptions } from "src/components/Modalservice/InputDialog";
import { TinyTheme } from "./domain/CourseEditModel";
import { debouncer } from "src/utils/debouncer";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import { IdAndNameAndSelectDto, IdAndNameDto } from "src/types";
import CoursePartTypeahead from "src/coursepart/CoursePartTypeahead";
import { urlService } from "src/services/NavService";
import coursePartService, { authorsAtom } from "src/coursepart/CoursePartService";
import WaitRipple from "src/components/WaitRipple";
import { Typeahead } from "react-bootstrap-typeahead";
import classes from "./course.module.scss";
import { ErrorService } from "src/components/Error/ErrorService";
import { AlertDialog, AlertOptions } from "src/components/Modalservice/AlertDialog";


const EditTheme = () => {

    const { courseId, themeId } = useParams();
    const [partsData] = useAtom(allPartsAtom);
    const debounce = useRef<debouncer | undefined>(undefined);
    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [authors] = useAtom(authorsAtom);

    const [authorsParts, setAuthorsParts] = useState<IdAndNameAndSelectDto[]>([]);
    const [selectedAuthor, setSelectedAuthor] = useState<IdAndNameDto | undefined>();
    const [loadingAuthor, setLoadingAuthor] = useState<boolean>(false);

    const [pickedOwnPart, setPickedOwnPart] = useState<string | undefined>(undefined);
    const [pickedOtherPart, setPickedOtherPart] = useState<string | undefined>(undefined);
    const [pickedAuthorPart, setPickedAuthorPart] = useState<string | undefined>(undefined);

    const [theme, setTheme] = useState<TinyTheme | undefined>(undefined);

    useEffect(() => {
        if (themeId && courseId) {
            setTheme(courseService.getTheme(courseId, themeId));
        }
    }, [courseId, themeId]);

    useEffect(() => {
        debounce.current = new debouncer(saveData, 500);
        return () => debounce.current?.clear();
    }, [])


    const saveData = (name: string, id: string, currentCourseId: string) => {
        courseService.updateThemeName(currentCourseId, id, name);
    }

    const updateThemeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.currentTarget.value;
        if (theme) {
            setTheme({ ...theme, Name: text });
            debounce.current && debounce.current.debounce(text, theme.Id, courseId);
        }
    }, [theme, courseId]);

    function deleteTheme(): void {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <div className="preserve-white">{t.getText("delete_theme_confirm")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then(async (res) => {
            if (res) {
                await courseService.deleteTheme(courseId!, themeId!);
                navigate(urlService.urlToCourse(courseId!));
            }
        });

    }

    function addNewPart(): void {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: InputOptions = {
                    title: t.getText("courseparts.createnew"),
                    message: <div>{t.getText("mail.noname")}</div>,
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    type: "input",
                    text: ""
                }
                return <InputDialog {...dprops} />
            },
            size: "md"
        }).then(async (text: string) => {
            try {
                const part = await courseService.addNewPart(text, courseId!, theme!.Id);
                if (!part) throw new Error("part not created");
            } catch (error) {
                console.error(error);
                ErrorService.setError({ message: t.getText("could.not.createpart"), header: t.getText("error"), when: new Date() });

            }
        });
    }


    const addOwnPart = useCallback(() => {
        if (pickedOwnPart) {
            const partData = partsData.OwnedParts.find(p => p.Id === pickedOwnPart);
            if (partData) {
                const newPart: IdAndNameAndSelectDto =
                    { Name: partData.Name, Id: partData.Id, Dirty: partData.Dirty || false }

                courseService.addPart(courseId!, theme!.Id, newPart)
            }
        }
    }, [pickedOwnPart]);


    const addOtherPart = useCallback(() => {
        if (pickedOtherPart) {
            const partData = partsData.EditParts.find(p => p.Id === pickedOtherPart);
            if (partData) {
                const newPart: IdAndNameAndSelectDto =
                    { Name: partData.Name, Id: partData.Id, Dirty: partData.Dirty || false }

                courseService.addPart(courseId!, theme!.Id, newPart)
            }
        }
    }, [pickedOtherPart]);

    const addAuthorPart = useCallback(() => {
        if (pickedAuthorPart) {
            const partData = authorsParts.find(p => p.Id === pickedAuthorPart);
            if (partData) {
                courseService.addPart(courseId!, theme!.Id, partData)
            }
        }
    }, [pickedAuthorPart]);


    const authorSelected = async (e: any) => {

        setPickedAuthorPart(undefined);

        if (!(e && e.length > 0 && e[0].Id)) {
            setAuthorsParts([]);
            setSelectedAuthor(undefined);
            return;

        }

        const id = e[0].Id;


        const author = authors.find(a => a.Id === id);
        setSelectedAuthor(author);
        setAuthorsParts([]);
        setLoadingAuthor(true);
        try {
            const parts = await coursePartService.getPartsByAuthor(id);
            setAuthorsParts(parts || []);
        }
        finally {
            setLoadingAuthor(false);
        }

    }

    const pickAuthorPart = useCallback((part: IdAndNameAndSelectDto) => {
        setPickedAuthorPart(part.Id);

        setAuthorsParts(ps => ps.map(p => { return { ...p, Selected: p.Id === part.Id } }));

    }, [])

    const downloadLectures = async () => {
        const text = await courseService.downloadThemeLectures(courseId!, themeId!);
        if (text) {
            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: AlertOptions = {
                        title: t.getText("lectures"),
                        message: <div className="preserve-white">{text}</div>,
                        languageService: t,
                        onSubmit: onSubmit,
                        className: ""
                    }
                    return <AlertDialog {...dprops} />
                },
                size: "md"
            });
        }
    }

    return (<>
        <div className="mb-3">
            <h3 className="mb-4">{theme?.Name}</h3>
        </div >

        <div className="row">
            <div className="col-sm-12 col-md-8 position-relative">
                <div className="form-group">
                    <label>{t.getText("name")}</label>
                    <input disabled={state?.readOnly} type="text" className="form-control" value={theme?.Name} onChange={updateThemeName} />
                </div>

                {!state?.readOnly &&
                    <div className="form-group mt-5">

                        <div className="mt-3 mb-4">
                            <h3>{t.getText("lectures")}</h3>
                            <div className="d-flex">
                                <div className="preserve-white mr-5">
                                    {t.getText("download.lectures")}
                                </div>
                                <div>
                                    <button onClick={downloadLectures} className="btn btn-small btn-primary">{t.getText("download")}</button>
                                </div>
                            </div>
                        </div>

                        <h3>{t.getText("courseparts")}</h3>

                        <h5 className="mt-3">{t.getText("add")}</h5>
                        <button onClick={addNewPart} className="my-3 btn btn-small btn-primary">{t.getText("courseparts.createnew")}</button>

                        <div>
                            <div className="my-3">
                                <strong>{t.getText("or.pick")}</strong>
                            </div>

                            <div className="row mb-5">

                                <div className="col-sm-6 col-xs-12">
                                    <div>{t.getText("your.courseparts")}</div>

                                    <CoursePartTypeahead items={partsData.OwnedParts} itemChosen={(i) => setPickedOwnPart(i.Id)} />

                                    <div>
                                        <button disabled={!pickedOwnPart} onClick={addOwnPart} className="my-3 btn btn-small btn-primary">{t.getText("add")}</button>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-xs-12">
                                    <div>{t.getText("others")}</div>

                                    <CoursePartTypeahead items={partsData.EditParts} itemChosen={(i) => setPickedOtherPart(i.Id)} />

                                    <div>
                                        <button disabled={!pickedOtherPart} onClick={addOtherPart} className="my-3 btn btn-small btn-primary">{t.getText("add")}</button>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="row mb-5">
                            <div className="col-4">
                                <div className="my-3">
                                    <strong>{t.getText("search.by.author")}</strong>
                                </div>

                                <Typeahead
                                    id="authors-select"
                                    labelKey="Name"
                                    minLength={1}
                                    multiple={false}
                                    onChange={authorSelected}
                                    options={authors}
                                    placeholder={t.getText("name")}
                                    dropup={true}
                                    positionFixed={true}

                                />


                            </div>

                            <div className="col-6">



                                {!loadingAuthor && selectedAuthor && <>
                                    <div className="my-3 d-flex">
                                        <strong>{selectedAuthor?.Name}</strong>

                                    </div>
                                    <div className={`${classes.authorPartsList} border`}>
                                        {authorsParts.map(p => <div key={p.Id} onClick={() => pickAuthorPart(p)} className={`dropdown-item ${p.Selected ? "selected" : ""}`}>{p.Name}</div>)}
                                    </div>
                                </>
                                }

                                {loadingAuthor && <WaitRipple />}

                            </div>
                            <div className="col-2">
                                <button disabled={!pickedAuthorPart} onClick={addAuthorPart} className="my-3 btn btn-small btn-primary">{t.getText("add")}</button>
                            </div>



                        </div>

                    </div>
                }


            </div>

            {!state?.readOnly &&

                <div className="col-md-4  col-12 d-flex flex-column">
                    <button onClick={deleteTheme} className="ml-auto mb-3 btn btn-warning">{t.getText("delete")}</button>

                </div>
            }

        </div>
    </>
    )
}

export default EditTheme;
