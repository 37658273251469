import { useI18n } from "src/utils/lni18n";
import { DiffTestDataDTO } from "../../DomainModels";
import MCTest from "../../MCTest/MCTest";
import TestDiffByReadOnly from "../TestDiffByReadOnly";


const MCTestDiff = (props: { data: DiffTestDataDTO }) => {

    const { data } = props;

    if (!data) return null;

    return <TestDiffByReadOnly data={data}
        publComponent={<MCTest readOnly={true} data={data.publDTO} onChange={() => { }} />}
        editComponent={<MCTest readOnly={true} data={data.editDTO} onChange={() => { }} />}
        />
}

export default MCTestDiff;