
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useI18n } from 'src/utils/lni18n';
import coursePartService, { onePartatom } from '../CoursePartService';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from 'src/components/Modalservice/ConfirmationDialog';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import LnIcon from 'src/components/LnIcon';
import { IdAndNameAndSelectDto } from 'src/types';
import { CoursePartHiddenTests } from '../domain/PublishPartViewDataDTO';
import classes from "./publish.module.scss";
import { AlertDialog } from 'src/components/Modalservice/AlertDialog';


type ClassAndTest = {
    Id: string,
    Name: string,
    Tests: IdAndNameAndSelectDto[];
}


export default function Publish() {

    const { partId } = useParams();
    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const dialog = useRef<HTMLDialogElement | null>(null);

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);

    const part = partData.Data;
    const publishData = part?.PublishData;


    const [excludedTestClasses, setExcludedTestClasses] = useState<ClassAndTest[] | undefined>(
        part?.PublishData.Classes.map(c => ({ Id: c.Id, Name: c.Name, Tests: part.NewTests.map(t => ({ Id: t.Id, Name: t.Name, Dirty: false })) })));

    const checkExcluded = (state: boolean, classId: string, test: IdAndNameAndSelectDto) => {
        setExcludedTestClasses(te => {
            if (te == null) return te;
            const classData = te?.find(cl => cl.Id === classId);
            if (!classData) return te;
            const testToChange = classData.Tests.find(te => te.Id === test.Id);
            if (testToChange) {
                testToChange.Selected = state;
            }

            return te;
        })
    }

    const doPublish = useCallback(async () => {

        let hiddenTests: CoursePartHiddenTests[] = [];
        if (excludedTestClasses && excludedTestClasses.length > 0) {
            hiddenTests = excludedTestClasses.map(cl => ({

                StudentClassId: cl.Id,

                TestIds: cl.Tests
                    .filter(te => te.Selected === true)
                    .map(te => te.Id)
            }))

                .filter(cl => cl.TestIds.length > 0);
        }


        const response = await coursePartService.publishPartRequest(partId!);
        if (!response) return;
        if (response.result) {
            const result = await dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: ConfirmationOptions = {
                        className: "",
                        title: t.getText("publish"),
                        message: <div className="preserve-white" >{response.message}</div>,
                        languageService: t,
                        show: true,
                        onClose: onCancel,
                        onSubmit: onSubmit,
                        buttons: ConfirmationButtons.YesNo
                    }
                    return <ConfirmationDialog {...dprops} />
                },
                size: "md"
            });

            if (result === true) {
                if (response.isAsync) {
                    coursePartService.publishPart(partId!, hiddenTests);
                }
                else {

                    if (dialog.current) {
                        dialog.current.showModal();
                    }

                    // do the publish thing
                    try {
                        await coursePartService.publishPart(partId!, hiddenTests);
                    } finally {
                        if (dialog.current) {
                            dialog.current.close();
                        }
                    }


                }
            }
        }

    }, [excludedTestClasses]);

    return <>

        <div className="d-flex mb-3">

            <h3 className="flex-fill">{t.getText("publish")}</h3>
            <div className="ml-auto d-flex align-items-center">
                {partData?.Data?.Dirty && <LnIcon name="alert" className='mr-3' />}
                {!partData.Data?.Locked &&
                    <button disabled={!partData?.Data?.Dirty} onClick={doPublish} className="btn btn-primary">{t.getText("publish")}</button>
                }
            </div>

        </div>
        <div className='d-flex mb-3'>
            <div className='ml-auto pr-4'>
                {partData?.Data?.Dirty && <span >{t.getText("courspart.not.published")}</span>}
                {!partData?.Data?.Dirty && <span >{t.getText("published")}</span>}
            </div>
        </div>

        {publishData && <div>

            {publishData.Classes && publishData.Classes.length > 0 &&
                <div className='mb-5'>
                    <hr />
                    <div className='d-flex'>
                        <div className='flex-fill'>
                            <h3 className='mb-3'>{t.getText("used.by")}</h3>
                            {publishData.Classes.map(c =>
                                <div key={c.Id} className="mb-3">
                                    <strong>{c.Name}</strong>
                                    <div>{t.getShortDateString(c.StartDate)}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{t.getShortDateString(c.ClassEndDate)}</div>
                                    <div>
                                        {t.getText("course")}:&nbsp;
                                        <Link to={`/course/${c.Course.Id}/general`}>{c.Course.Name}</Link>

                                    </div>
                                </div>
                            )
                            }
                        </div>
                        <div className='ml-3 '>
                            <div className='alert alert-danger'>
                                {t.getText("publish.warning")}
                                <LnIcon name="alert" className='icon-small ml-2 mb-2' />
                            </div>

                            <div>
                                {part.NewTests.length > 0 && excludedTestClasses && excludedTestClasses.length > 0 && <>
                                    <div className='preserve-white mb-5'>{t.getText("hidden.tests.at.publish.explain")}</div>

                                    <table className='table table-condensed table-striped'>
                                        <thead>
                                            <tr>
                                                <th>
                                                    {t.getText("test")}
                                                </th>
                                                {part.NewTests.map(c => <th key={c.Id}>
                                                    <div title={c.Name} className={`mr-3 ${classes.testName}`}>{c.Name}</div>
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {excludedTestClasses.map(cl => <tr key={cl.Id}>

                                                <td>{cl.Name}</td>
                                                {cl.Tests.map(te => <td key={te.Id} title={te.Name}>

                                                    <label>
                                                        <input type='checkbox'
                                                            checked={te.Selected}
                                                            onChange={(e) => checkExcluded(e.currentTarget.checked, cl.Id, te)} />&nbsp;&nbsp;{t.getText("hide")}
                                                    </label>
                                                </td>)}
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </>
                                }
                            </div>

                        </div>
                    </div>

                </div>}
            {publishData.OtherCourses && publishData.OtherCourses.length > 0 && <div>
                <hr />
                <h3 className='mb-3'>{t.getText("part.of.following.courses")}</h3>
                {publishData.OtherCourses.map(c => <div key={c.Id} className='mb-3'>
                    <Link to={`/course/${c.Id}/general`}>{c.Name}</Link>
                </div>)}
            </div>}


        </div>}


        <dialog ref={dialog} className="md">
            <AlertDialog 
                title={t.getText("publish")}   
                languageService={t}
                message={t.getText("publishing")}
                className=""
                onSubmit={(d) => { }}
                noButton={true} />
        </dialog>

    </>



}