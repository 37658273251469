import { atom, getDefaultStore } from "jotai";
import audioContextService from "../Player/waveEdit/AudioContextService";
import { produce } from "immer";
import { AudioSequence } from "../Player/waveEdit/AudioSequence";
import VUCalculator from "./VuMeter/VUCalculator";

export const MAX_MINUTES_OF_RECORDING = 2;

export const RecorderStates = {
    UNINITIALIZED: 0,
    RECORDING: 1,
    PAUSED: 2,
    FINISHED: 3,
};

export type Recordingstate = {
    state: number,
    recordingLength: number,
    gain: number,
    maxLenInSec: number,
    dialogOpen: boolean

}

const store = getDefaultStore();
const recordStateAtom = atom<Recordingstate>({ dialogOpen: false, state: 0, recordingLength: 0, gain: 0, maxLenInSec: MAX_MINUTES_OF_RECORDING * 60 });
recordStateAtom.debugLabel = "recordStateAtom";

export const gainAtom = atom<number>(1.5);
gainAtom.debugLabel = "gainAtom";

export const recordingAtom = atom(get => get(recordStateAtom));

export class RecorderService {

    recordingNode?: AudioWorkletNode;
    currentPort?: MessagePort;
    recordedData?: Float32Array[];
    audioContext?: AudioContext;
    mono = true;
    gainInNode?: GainNode;
    vUCalculator?: VUCalculator;
    micSourceNode?: MediaStreamAudioSourceNode;
    analyserNode?: AnalyserNode;
    gainOutNode?: GainNode;


    constructor() {

        store.sub(gainAtom, () => {
            if (this.gainInNode) {
                let g = store.get(gainAtom);
                this.gainInNode.gain.value = g;
            }

        });
    }

    public get dialogOpen(){
        return store.get(recordStateAtom).dialogOpen; 
    }

    public set dialogOpen(value: boolean){
        store.set(recordStateAtom, produce(store.get(recordStateAtom), 
        (draft) => { draft.dialogOpen = value }));
    }

    private setState(state: number) {
        var data = store.get(recordStateAtom);
        if (!data) return;

        const newstate = produce(data, (draft) => {
            draft.state = state;
        })

        store.set(recordStateAtom, newstate);
    }

    private setRecLenAndGain(len: number, gain: number) {
        var data = store.get(recordStateAtom);
        if (!data) return;

        const newstate = produce(data, (draft) => {
            draft.recordingLength = len;
            draft.gain = gain;
        })

        store.set(recordStateAtom, newstate);
    }

    private recordingEventCallback = async (event: MessageEvent<any>) => {
        switch (event.data.message) {


            case 'MAX_RECORDING_LENGTH_REACHED':
                this.setState(RecorderStates.FINISHED);
                this.recordedData = event.data.buffer;

            case 'UPDATE_RECORDING_LENGTH_AND_VISUALIZERS':
                if (this.audioContext) {

                    const len = (((event.data.recordingLength / this.audioContext.sampleRate * 100) / 100) / MAX_MINUTES_OF_RECORDING / 60) * 100;
                    const vol = this.vUCalculator!.GetVolume();
                    this.setRecLenAndGain(len, 1 - vol);
                }
                break;

            case 'SHARE_RECORDING_BUFFER':
                this.recordedData = event.data.buffer;
                this.setState(RecorderStates.PAUSED);
                break;
        }
    };

    public Reset() {
        this.setRecLenAndGain(0, 0);
        this.setState(RecorderStates.UNINITIALIZED);
        this.recordedData = undefined;

        this.gainInNode?.disconnect();
        this.gainInNode = undefined;

        this.recordingNode?.disconnect();
        this.recordingNode = undefined;

        this.micSourceNode?.disconnect();
        this.micSourceNode = undefined;

        this.gainOutNode?.disconnect();
        this.gainOutNode = undefined;

        this.analyserNode?.disconnect();
        this.analyserNode = undefined;

        this.audioContext?.close();
        this.audioContext = undefined;

    }

    public async initializeAudio(asMono: boolean) {

        this.setState(RecorderStates.UNINITIALIZED);
        this.mono = asMono;
        this.audioContext = new AudioContext({
            sampleRate: audioContextService.Context.sampleRate,
        });

        // Get user's microphone and connect it to the this.audioContext.
        const micStream = await navigator.mediaDevices.getUserMedia({
            audio: {
                echoCancellation: false,
                autoGainControl: false,
                noiseSuppression: false
            }
        });

        // const compressor = new DynamicsCompressorNode(this.audioContext, {
        //     threshold: -20,
        //     knee: 10,
        //     ratio: 5,
        //     attack: 0,
        //     release: 0.25,
        //   });

        this.micSourceNode = new MediaStreamAudioSourceNode(this.audioContext,
            { mediaStream: micStream });
            
        this.gainInNode = new GainNode(this.audioContext);
        this.gainOutNode = new GainNode(this.audioContext);
        this.analyserNode = new AnalyserNode(this.audioContext);

        this.vUCalculator = new VUCalculator(-40, this.analyserNode, 32, 6);

        const recordingProperties = {
            numberOfChannels: this.micSourceNode.channelCount,
            sampleRate: this.audioContext.sampleRate,
            maxFrameCount: this.audioContext.sampleRate * MAX_MINUTES_OF_RECORDING * 60
        };

        if (!this.recordingNode) {
            this.recordingNode = await this.setupRecordingWorkletNode(recordingProperties, this.audioContext);
        }



        // const waveform = new Waveform('#recording-canvas', analyserNode, 32);
        // const vuMeter = new VUMeter('#vu-meter', -40, analyserNode, 32, 6);

        // We can pass this port across the app and let components handle
        // their relevant messages.
        //const visualizerCallback = setupVisualizers(waveform, vuMeter);

        this.recordingNode.port.onmessage = this.recordingEventCallback;
        this.clearRecording();

        this.gainInNode.gain.value = 1.5;
        this.gainOutNode.gain.value = 0;

        this.micSourceNode
            .connect(this.gainInNode)
            .connect(this.analyserNode)
            .connect(this.recordingNode)
            .connect(this.gainOutNode)
            .connect(this.audioContext.destination);
    }


    public clearRecording() {
        if (this.recordingNode) {
            this.recordingNode.port.postMessage({
                message: 'CLEAR_RECORDING_STATE',
            });
        }
    }

    public getDataAsAudioSequences() {
        if (!this.recordedData) {
            return undefined;
        }
        const rate = audioContextService.Context.sampleRate;
        return this.recordedData.map(r => new AudioSequence(rate, r));
    }

    /**
 * Create and set up a WorkletNode to record audio from a microphone.
 * @param {object} recordingProperties
 * @return {AudioWorkletNode} Recording node related components for
 * the app.
 */
    private async setupRecordingWorkletNode(recordingProperties: any, audioContext: AudioContext) {
        try {
            await audioContext.audioWorklet.addModule('/workers/recording-processor.js');
        }
        catch (e) {
            console.error(e);
        }
        const workletRecordingNode = new AudioWorkletNode(
            audioContext,
            'recording-processor',
            {
                processorOptions: recordingProperties,
            },
        );

        return workletRecordingNode;
    }

    public startRecord = () => {
        if (!this.recordingNode) return;
        this.recordedData = undefined;
        this.setState(RecorderStates.RECORDING);
        this.recordingNode.port.postMessage({
            message: 'UPDATE_RECORDING_STATE',
            setRecording: true,
            mono: this.mono
        });
    }

    public stopRecord = () => {
        if (!this.recordingNode) return;
        this.setState(RecorderStates.PAUSED);
        this.recordingNode.port.postMessage({
            message: 'UPDATE_RECORDING_STATE',
            setRecording: false,
            mono: this.mono
        });


    }

}

const recorderService = new RecorderService();
export default recorderService;
