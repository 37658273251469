import { useI18n } from "src/utils/lni18n";
import { DiffTestDataDTO } from "../../DomainModels";
import DiffTestGeneral from "../DiffTestGeneral";
import DOMPurify from 'dompurify';

const QADiff = (props: { data: DiffTestDataDTO }) => {

    const { data } = props;
    const { languageService: t } = useI18n();

    if (!data) return null;

    return <div className="row mt-4">
        <div className="col-lg-6 border-right">
            <h3>{t.getText("published")}</h3>
            <DiffTestGeneral data={data.publDTO} />
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.publDTO.TestData) }} ></div>
        </div>
        <div className="col-lg-6">
            <h3>{t.getText("differences")}</h3>
            <DiffTestGeneral data={data.editDTO} />
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.editDTO.TestData) }} ></div>
        </div>

    </div >

}

export default QADiff;