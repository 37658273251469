import { useLoaderData } from "react-router-dom";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import classes from "../tests.module.scss";
import { useEffect, useRef } from "react";
import { usePartPath } from "src/utils/usePartPath";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DiffTestDataDTO } from "../DomainModels";
import { testDataAtom } from "../TestService";
import DiffTestComponent from "./DiffTestComponent";

const DiffOneTest = (props: {}) => {

    const dialog = useRef<HTMLDialogElement | null>(null)
    const partPath = usePartPath();
    const { languageService: t } = useI18n();
    const [generalTestData] = useAtom(testDataAtom);
    const data: DiffTestDataDTO = useLoaderData() as DiffTestDataDTO;

    if (!data) return null;

    useEffect(() => {
        if (data) {
            dialog.current?.showModal();
        };

        return () => dialog?.current?.close();
    }, [data])

    const typeText = generalTestData.testTypes.find(t => t.TestType === data.editDTO?.Type);

    return (
        <dialog className="xl xx" ref={dialog}>

            <span className="moveable" data-dialog-draggable>
                <ModalHeader>{t.getText("differences")}</ModalHeader>
            </span>
            <ModalBody>
                <div className="preserve-white">{typeText?.Description}</div>
                <div className={`${classes.testEdit}`} >
                    <DiffTestComponent  data={data} />

                </div>

            </ModalBody>
            <ModalFooter>
                <Link className="btn btn-primary btn-sm" to={`${partPath}/testsDiff`} >{t.getText("close")}</Link>
            </ModalFooter>

        </dialog>)
}

export default DiffOneTest;

